<template>
  <div class="notice-page">
    <div class="boxxqq_div11">
      <div class="boxxqq_div12">
        <el-button type="primary" icon="el-icon-plus" @click="add">发布</el-button>
      </div>
      <div class="content" style="height: 660px">
        <el-table
          :data="tableData"
          style="width: 100%"
          max-height="660"
          :header-cell-style="{
            color: '#333333',
            backgroundColor: '#FBFBFD',
          }"
          row-key="id"
          border
          lazy>
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="62">
          </el-table-column>
          <el-table-column
            prop="MsgName"
            label="标题"
            align="center"
            min-width="200">
          </el-table-column>
          <el-table-column
            prop="SendUserName"
            label="创建人"
            align="center"
            width="180">
          </el-table-column>
          <el-table-column
            prop="ReceiveUserName"
            label="接收人"
            align="center"
            min-width="180">
          </el-table-column>
          <el-table-column
            prop="CreateDate"
            align="center"
            label="日期"
            min-width="180"
          >
          </el-table-column>
          <el-table-column
            label="下载附件"
            align="center"
            width="120">
            <template slot-scope="scope">
              <span @click="seeFile(scope.row)"  style="color: #3978E7;font-weight: 400" class="p-hover">附件</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="180">
            <template slot-scope="scope">
              <!-- <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">全部撤回</el-button> -->
              <el-button
                style="font-size: 16px;position: relative;"
                type="text"
                @click="handleviews(scope.$index, scope.row)">
                <img src="../../../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;position: absolute;left: -20px">
                查看详情</el-button>
              <el-button
                type="text"
                style="font-size: 16px;position: relative;margin-left: 36px"
                @click="handleDelete(scope.$index, scope.row)">
                <img src="../../../assets/image/notice/retun.png" alt="" style="width: 16px;height: 16px;position: absolute;left: -20px">
                  撤回
               </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="num"
          :page-size="nums"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div class="notice-dialog">
        <el-dialog
          :visible.sync="dialogVisible"
          width="30%"
          v-dialogDrag>
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">发布通知</span>
          </div>
          <el-form ref="form" label-width="80px">
            <el-form-item label="主题：">
              <el-input v-model="msgname"></el-input>
            </el-form-item>
            <el-form-item label="内容：">
              <el-input type="textarea" v-model="msgtext"></el-input>
            </el-form-item>
            <el-form-item label="附件：">
              <el-col class="line" :span="10">
              <el-upload
                class="upload-demo"
                ref="newupload"
                accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"
                multiple
                action="#"
                :file-list="fileLists"
                :auto-upload="false"
                :on-change="handleFileChange"
                :before-remove="handleRemove"
              >
                <el-button size="medium" plain icon="el-icon-top" style="color: #4d90f1;border: 1px solid #4d90f1">上传文件</el-button>
              </el-upload>
              </el-col>
              <el-col :span="14">
                <div>
                 <span style="font-size: 16px">是否发送短信通知：</span>
                  <el-radio-group v-model="radio">
                    <el-radio :label="2">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-form-item>
          </el-form>
          <div style="display: flex;overflow: auto;max-height: 400px">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">姓名:</el-checkbox>
            <el-checkbox-group v-model="listItem" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="(data,index) in tableData1" :label="data" :key="index" >{{data.UserName}}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="btn">
            <button  @click="dialogVisible = false">取消</button>
            <el-button @click="sendout" :loading="loading">确定</el-button>
          </div>
        </el-dialog>
      </div>
      <div class="noticeDetails-dialog">
        <el-dialog
          top="35vh"
          :visible.sync="handleEditDialog"
          width="35%"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">通知公告详情</span>
          </div>
          <el-form  label-width="100px" :model="tableDetailed">
            <el-form-item label="主题:">
              <el-input type="textarea" autosize v-model="tableDetailed.MsgName" readonly ></el-input>
            </el-form-item>
            <el-form-item label="创建人:">
              <el-input v-model="tableDetailed.SendUserName" readonly></el-input>
            </el-form-item>
            <el-form-item label="接收人:">
              <el-input v-model="tableDetailed.ReceiveUserName" readonly ></el-input>
            </el-form-item>
            <el-form-item label="创建时间:">
              <el-input v-model="tableDetailed.CreateDate" readonly></el-input>
            </el-form-item>
            <el-form-item label="内容:">
              <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 4}" v-model="tableDetailed.MsgText" readonly ></el-input>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>
      <div class="withdraw">
        <el-dialog
          top="35vh"
          :visible.sync="handleWithdrawDialog"
          width="510px"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">提示</span>
          </div>
          <div style="text-align: center">
            <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
            <span style="font-size: 18px;color: #666">此操作将永久撤回该消息，是否继续？</span>
          </div>
          <div class="btn">
            <button  @click="handleWithdrawDialog = false">取消</button>
            <button @click="withdraw">确定</button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import research from "../../../api/research"
import reseacrh from "@/api/research";
import {downloadFileId} from "@/utils/downloadUrl";
import {NoticeFileId} from "@/utils/downloadUrl.js"
export default {
  data(){
    return{
      loading: false,
      radio: 2,
      phoneList: "", // 发短信的手机号
      fileLists: [], // 文件列表
      item: {},// 撤回数据
      handleWithdrawDialog: false, // 撤回弹框
      checkAll: false,
      isIndeterminate: true,
      handleEditDialog: false,
      tableDetailed: {},
      dialogVisible:false,
      msgname:"",
      msgtext:"",
      tableData:[],
      tableData1:[],
      idlist:[],
      listItem: [],
      total:0,
      num:1,
      nums:13,
    }
  },
  created(){
    this.ton()
  },
  methods:{
    handleRemove(file, fileList) {
      this.fileLists = fileList;
    },
    handleFileChange(file, fileList) {
      this.fileLists = fileList;
      // console.log(this.fileLists)
    },
    handleviews(i,item) {
      this.tableDetailed = item
      this.handleEditDialog = true
      // console.log('查看详情')
    },
    withdraw() {
      research.dataGuid({DataGuid:this.item.DataGuid}).then(res=>{
        if(res.data.Status==1){
          this.ton()
          this.handleWithdrawDialog = false
          this.$message({
            type: 'success',
            message: '撤回成功!'
          });
        }else{
          this.$message.error(res.data.Message)
        }
        // console.log(res);
        // })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消撤回'
        });
      });
    },
    handleDelete(i,item){//单个撤回
      this.handleWithdrawDialog = true
      this.item = item
    },
    seeFile(item) { // 查看附件  除图片除外
      reseacrh.preFinancFile(item.DataGuid).then(res=>{
        if (res.data.Status==1) {
          if (res.data.ExtraData.length == 0) {
            this.$message.warning('该通知公告没有上传附件')
            return false
          }
          res.data.ExtraData.forEach( items => {
            // if(!/\.(pdf|PDF|png|PNG|jpg|JPG|jpeg|JPEG)$/.test("." +items.Suffix)){
              this.downloadfile(items.Id,item.Id)
            // }
          })
        }
      })
    },
    handleEdit(){//全部撤回

    },
    handleSizeChange(val){
      this.nums=val
      this.ton()
    },
    handleCurrentChange(val){
      this.num=val
      this.ton()
    },
    ton(){
      research.sendoutmsg({TopicId:"",PageIndex:this.num-1,PageSize:this.nums,SysFlag:0}).then(res=>{
        this.tableData=res.data.Entity
        this.total=res.data.TotalRowsCount
      })
    },
    sendout(){
      this.loading = true
      let fd = new FormData();
      this.fileLists.forEach(file => {
        fd.append('file',file.raw)
      })
      if(this.listItem.length==0){
          this.loading = false
          this.$message.error("请选择接收人")
        // let useid=sessionStorage.getItem("Id")
        // research.allmember(useid).then(res=>{
        //   this.loading = false
        //   var list=res.data.Entity.map(item=>{
        //     return item.Id
        //   })
        //   var allobj={
        //     TopicId:"",
        //     users:list,
        //     msgname:this.msgname,
        //     msgtext:this.msgtext,
        //     SysFlag:0
        //   }
        //   fd.append("data", JSON.stringify(allobj));
        //   research.allmsg(fd).then(res=>{
        //     if(res.data.Status==1){
        //       // this.$message.success({
        //       //   type: 'success',
        //       //   message: '发布成功',
        //       // })
        //       this.$message.success(res.data.Message)
        //       this.loading = false
        //       this.dialogVisible=false
        //       this.msgname=""
        //       this.msgtext=""
        //       this.ton()
        //     }else{
        //       this.loading = false
        //       alert(res.data.Message)
        //     }
        //   })
        // })
      }else{
        this.loading = false
        this.idlist = this.listItem.map(item=>{  // 选择的用户的id数组
          return item.Id
        })
        var arr = []
        this.listItem.forEach((item)=> {
          if (item.PhoneNumber) {
            arr.push(item.PhoneNumber)
          }
        })
        console.log(this.phoneList,typeof this.phoneList)
        this.phoneList = [...new Set(arr)]
        console.log(this.phoneList,typeof this.phoneList)
        this.phoneList = this.phoneList.join(',')
        console.log(this.phoneList,typeof this.phoneList)
        var allobj={
          // TopicId:"",
          SmsJson: "",
          users:this.idlist,
          msgname:this.msgname,
          msgtext:this.msgtext,
          SysFlag:0
        }
          if (this.radio == 1) { //是否发送短信
              // var text ="【" + this.msgname + "】" + "你有一个主题为" + this.msgname + "的通知，请登录科研管理系统查看。"
              var text = "你有一个主题为" + this.msgname + "的通知，请登录科研管理系统查看。"
              var obj = {
                  ContactNumbers: this.phoneList,
                  Content: text
              }
              allobj.SmsJson = JSON.stringify(obj)
              console.log(allobj,'值')
          }
        fd.append("data", JSON.stringify(allobj));
        research.allmsg(fd).then(res=>{
          if(res.data.Status==1){
            this.loading = false
            this.$message.success(res.data.Message)
            this.dialogVisible=false
            allobj.SmsJson = ""
            this.idlist = []
            this.listItem = []
            this.msgname=""
            this.msgtext=""
            this.ton()
          }else{
            this.loading = false
            alert(res.data.Message)
          }
        })
      }

    },
    add(){
      this.phoneList = ""
      this.radio = 2
      this.dialogVisible=true
      let useid=sessionStorage.getItem("Id")
      research.allmember(useid).then(res=>{
        if(res.data.Status==1){
          this.tableData1=res.data.Entity
        }else{
          alert(res.data.Message)
        }
      })
    },
    handleSelectionChange(item){
      console.log(item);
      this.idlist=item.map(item=>{
        return item.Id
      })
    },
    handleCheckAllChange(val) {
      console.log(val,typeof val)
      var list = JSON.parse(JSON.stringify(this.tableData1))
      this.phoneList = JSON.parse(JSON.stringify(this.tableData1)).map(item => {
        var phone = ""
         phone += item.PhoneNumber
        return phone
      })
      this.phoneList = this.phoneList.join(',')
      console.log(this.phoneList,typeof this.phoneList)
      list = list.map(item=>{
        return item.Id
      })
      this.idlist = val ? list : [];
      this.listItem = val ? this.tableData1 : []
      console.log(this.idlist)
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.tableData1.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableData1.length;
      // console.log(value)
      // console.log(this.idlist)
    },
    downloadfile(DataId,MessageId){
      // downloadFileId(id)
      NoticeFileId(DataId,MessageId)
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin.scss";
.notice-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .boxxqq_div11{
    width: 96%;
    //height: 600px;
    height: 820px;
    //margin-left: 2%;
    margin-top: 30px;
    background: #FFFFFF;
    opacity: 1;
    padding: 1% 1% 0 1%;
    border-radius: 10px;
    .boxxqq_div12{
      width: 100%;
      margin-bottom: 10px;
      /deep/.el-button {
        @include add-size($font_size_16);
      }
    }
  }
}
.notice-dialog /deep/.el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 5px;
      }
      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .el-form {
    .el-form-item {
      .el-form-item__label {
        @include add-size($font_size_16);
        color: #666666;
      }
      .el-input__inner {
      }
      .el-input {
        @include add-size($font_size_16);
        margin-right: 10px;
      }
      .el-textarea__inner {
        @include add-size($font_size_16);
      }
    }
  }
  .el-checkbox {
    @include add-size($font_size_16);
  }
  .el-checkbox__label {
    width: 50px;
    margin-bottom: 10px;
    margin-right: 5px;
    color: #666666;
    @include add-size($font_size_16);
  }
  .btn {
    margin-top: 30px;
    text-align: center;
    button:nth-child(1) {
      width: 180px;
      background: #ffffff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #3388ff;
      margin-right: 20px;
    }
    button:nth-child(2) {
      width: 180px;
      //background: #ffffff;
      border: 1px solid #3388ff;
      background: #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
    }
  }
}
.noticeDetails-dialog /deep/.el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 5px;
      }
      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .el-form {
    .el-form-item {
      .el-form-item__label {
        @include add-size($font_size_16);
        color: #666666;
      }
      .el-input__inner {
        background: #F4F7F9;
      }
      .el-input {
        @include add-size($font_size_16);
        margin-right: 10px;
      }
      .el-textarea__inner {
        @include add-size($font_size_16);
        opacity: 1;
        background: #F4F7F9;
      }
    }
  }
}
.withdraw /deep/.el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    margin-bottom: 20px;
    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 5px;
      }
      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .btn {
    margin-top: 52px;
    text-align: center;
    button:nth-child(1) {
      width: 180px;
      background: #ffffff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #3388ff;
      margin-right: 20px;
    }
    button:nth-child(2) {
      width: 180px;
      //background: #ffffff;
      border: 1px solid #3388ff;
      background: #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
    }
  }
}
.block {
  margin-top: 20px;
}
.content /deep/ .el-button {
  color: #3978E7;
  font-weight: 400;
}
.content /deep/.el-table th {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.content /deep/.el-table__row {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.content {
  ::-webkit-scrollbar {
    width:8px!important;
    height: 4px;

  }
  ::-webkit-scrollbar-thumb {
    //width: 20px;
    //height: 4px;
    background-color: #1890ff;
    border-radius: 10px;
  }
}
::-webkit-scrollbar {
  width: 8px!important;
  height: 8px;
  background-color: #1890ff;
  border-radius: 10px;
}
.p-hover:hover {
  cursor:pointer
}
</style>
